<template>
  <div class="search-bar">
    <div class="search-bar-main">
      <div class="dropdown">
        <el-dropdown placement="bottom" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ queryInfo.inputs.flag == 0 ? '找设备' : '找需求'
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">找设备</el-dropdown-item>
            <el-dropdown-item command="1">找需求</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <input
        type="text"
        v-model="queryInfo.inputs.name"
        placeholder="请输入关键词搜索"
        class="input" />
      <div class="btn-search" @click="goSearch">搜索</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        queryInfo: {
          inputs: { flag: 0, name: '' },
        },
      }
    },
    methods: {
      handleCommand(command) {
        this.queryInfo.inputs.flag = command * 1
      },
      async goSearch() {
        if (this.queryInfo.inputs.flag == 0) {
          this.$router.push({ name: 'deviceList', query: { searchValue: this.queryInfo.inputs.name } })
 
        } else {
          this.$router.push({ name: 'needList', query: { searchValue: this.queryInfo.inputs.name } })

        }
      },
    },
  }
</script>

<style scoped></style>
