import request from "./request.js";

//轮播图
export function getBannerList(data) {
    return request({
        url: "/web/home/banner/list",
        method: 'post',
        data
    })
}
/* 三级菜单 */
export function getClassifyList(data) {
    return request({
        url: "/web/home/type/list",
        method: 'post',
        data
    })
}
/* 产品信息 */
export function getProductList(data) {
    return request({
        url: "/web/home/list",
        method: 'post',
        data
    })
}

/* 搜索 */
export function getSearchList(data) {
    return request({
        url: "/web/home/search/device",
        method: 'post',
        data
    })
}

/* 地区 */
export function getRegionList(data) {
    return request({
        url: "/web/areas/list",
        method: 'post',
        data
    })
}

/* 获取订单列表 */
export function getOrderList(data) {
    return request({
        url: "/web/user/order/detail",
        method: 'post',
        data
    })
}

/* 中期结算，最终结算申请 */
export function setOrderState(data) {
    return request({
        url: "/web/user/order/end",
        method: 'post',
        data
    })
}