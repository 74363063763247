<template>
  <div class="footer">
    <div class="footer-feature">
      <div class="feature-main">
        <div class="feature-item"><img src="~@/assets/images/feature_icon_01.png" alt="">多城市租赁</div>
        <i class="divider"></i>
        <div class="feature-item"><img src="~@/assets/images/feature_icon_02.png" alt="">多服务网点</div>
        <i class="divider"></i>
        <div class="feature-item"><img src="~@/assets/images/feature_icon_03.png" alt="">多种类机械</div>
        <i class="divider"></i>
        <div class="feature-item"><img src="~@/assets/images/feature_icon_04.png" alt="">高响应客服</div>
      </div>
    </div>
    <div class="footer-main">
      <div class="left-area">
        <div class="row">
          <div class="logo"></div>
          <div class="tel">
            <div class="tel-item">客服电话：<big>0591-83055119</big></div>
           
          </div>
        </div>
        <div class="link">
          <span class="link-item" @click="$router.push({ name: 'serviceAgreement' })">平台服务条款</span>
          <i> | </i>
          <span class="link-item">关于我们</span>
        </div>
      </div>
      <div class="ewm">
        <div class="ewm-item"><img src="~@/assets/images/emw_img_01.png" alt="">扫一扫关注公众号</div>
        <div class="ewm-item"><img src="~@/assets/images/emw_img_02.png" alt="">小米商城</div>
        <div class="ewm-item"><img src="~@/assets/images/emw_img_03.png" alt="">ios商城</div>
      </div>
    </div>
    <div class="footer-copyright">Copyright © 2022-2024 福建闻煦科技有限公司 版权所有  |  闽ICP备2022015685号  |  公网安备 441********939号</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
    methods: {
    },

  }
</script>

<style scoped>
.ewm-item{
    padding-left: 30px;
}
</style>