import request from "./request.js";


// 登陆
export function login(data) {
	return request({
		url: "/web/user/login",
		method: "post",
		data: data,
	});
}


// 手机验证码
export function phone_code(data) {
	return request({
		url: "/web/user/phone_code",
		method: "post",
		data: data,
	});
}


// 注册
export function register(data) {
	return request({
		url: "/web/user/register",
		method: "post",
		data: data,
	});
}


// get old data
export function get_old_data(data) {
	return request({
		url: "/web/user/old_data",
		method: "post",
		data: data,
	});
}

// 获取用户
export function setuser_info(data) {
	localStorage.setItem("user", JSON.stringify(data.user_info))
	localStorage.setItem("token", data.token)
}



// 获取用户
export function getuser() {
	return JSON.parse(localStorage.getItem("user"))
}


export function setuser(data) {
	localStorage.setItem("user", JSON.stringify(data))
}



// 获取token
export function gettoken() {
	let token = localStorage.getItem("token")
	return token
}



// 注册
export function getmsgnum(data) {
	return request({
		url: "/web/message/num",
		method: "post",
		data: data,
	});
}

// 短信发送
export function send(data) {
	return request({
		url: "/web/code/send",
		method: "post",
		data: data,
	});
}

// 短信验证
export function check(data) {
	return request({
		url: "/web/code/loginCheck",
		method: "post",
		data: data,
	});
}