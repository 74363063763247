<template>
  <div class="right-nav">
    <div id="showkfPanel" class="right-nav-item" @click="showPanel"><i class="icon icon-customer-service"></i>在线客服</div>
    <div class="right-nav-item"><i class="icon icon-down"></i>下载APP</div>
    <div class="right-nav-item"><i class="icon icon-wx"></i>公众号</div>
    <div class="right-nav-item" @click="backTop"><i class="icon icon-back-top"></i>回到顶部</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
    methods: {
      backTop() {
       let top = document.documentElement.scrollTop || document.body.scrollTop
       const timeTop = setInterval(() => {
         document.body.scrollTop = document.documentElement.scrollTop = top -= 50
         if (top <= 0) {
           clearInterval(timeTop)
         }
       }, 10)
      },
      showPanel() {
        window._MEIQIA('showPanel')
      },
    },

  }
</script>

<style scoped>
</style>