<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style>
.BMap_cpyCtrl {
   display: none;
 }
.anchorBL {
   display: none;
}

/**手/可点击 */
.device{
    cursor:pointer;
}
.cursor{
    cursor:pointer;
    color: #0000ff;
}

</style>
