import Vue from 'vue'
import App from './App.vue'
import { PiniaVuePlugin } from 'pinia'
Vue.use(PiniaVuePlugin)
import pinia from '@/store/pinia'
import router from './router'
import ElementUI from 'element-ui'
import './element-variables.scss'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'ZV2MX6x3OWMV1aX9ACj15QTB32PFRj1C'
})

import './assets/css/style.scss'


Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount('#app')
