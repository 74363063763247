<template>
  <div class="device-category-main">
    <div class="device-category" @mouseleave="showDeviceCategoryChild = false">
      <!-- 一级菜单 -->
      <div
        class="device-category-item"
        v-for="(item, index) in classifylist"
        :key="index"
        @mouseenter="showDeviceCategory(item.children)">
        <div class="title" @click="goDeviceTag(item)">
          {{ item.type_name }}<i class="f-icon icon-arrow"></i>
        </div>
        <div class="btn">
          <!-- 二级菜单 -->
          <div class="submenu" v-for="(item2, index) in item.children" :key="index">
            <span @click="goDeviceTag(item2)">{{ item2.type_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="device-category-child"
      v-show="showDeviceCategoryChild"
      @mouseenter="showDeviceCategoryChild = true"
      @mouseleave="showDeviceCategoryChild = false">
      <div class="btn-close" @click="showDeviceCategoryChild = false">关闭</div>
      <div class="device-category-item" v-for="(submenuTitle, index) in childList" :key="index">
        <!-- 二级菜单标题 -->
        <div class="title" @click="goDeviceTag(submenuTitle)" v-if="submenuTitle.children != null">
          {{ submenuTitle.type_name }}
        </div>
        <div class="btn">
          <!-- 三级菜单 -->
          <div
            class="submenu-three"
            @click="goDeviceTag(item3)"
            v-for="(item3, index) in submenuTitle.children"
            :key="index">
            <span>{{ item3.type_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getClassifyList } from '@/api/home'
  import kits from '@/kits'
  export default {
    name: 'MechanicalSharedClassification',
    emits:['changeType'],
    data() {
      return {
        showDeviceCategoryChild: false,
        /* 三级菜单列表 */
        classifylist: [],
        /* 三级列表子菜单 */
        childList: {},
        /* 获取点击值 */
        classifyVal: {},
      }
    },

    mounted() {
      /* 获取分类列表 */
      getClassifyList()
        .then((res) => {
          if (res.status != 200) return
          this.classifylist = kits.arr.tree_set(res.data, 'parent_id', 'type_id')
        })
        .catch((err) => {
          // 出错时要做的事情
          return err
        })
    },

    methods: {
      /* 获取第三级菜单 */
      showDeviceCategory(res) {
        this.childList = res
        this.showDeviceCategoryChild = true
      },
      /* 三级菜单标签转跳 或者 获取值*/
      goDeviceTag(item) {
        if (this.$route.fullPath == '/home') {
          this.$router.push({
            name: 'deviceList',
            query: { type_id: item.type_id, type_name: item.type_name },
          })
        } else {
          this.$emit('changeType',{ type_id: item.type_id, type_name: item.type_name })
          this.classifyVal.type_id = item.type_id
          this.classifyVal.type_name = item.type_name
          console.log(this.classifyVal)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .device-category-main {
    width: 730px;
    height: 500px;
    border-radius: 6px;
    position: absolute;
    left: calc(50% - 600px);
    top: 45px;
    z-index: 9;
  }
  .device-category {
    height: 100%;
    width: 230px;
    background-color: rgba(255, 255, 255, 0.96);
    border-radius: 6px;
    padding: 15px 0px 0 23px;
    .device-category-item {
      margin-top: 28px;
    }
    .title {
      font-size: 18px;
      color: #323232;
      font-weight: bold;
      margin-bottom: 12px;
    }
    .title .icon-arrow {
      background-image: url(~@/assets/images/icon_arrow_right_black.png);
      margin-left: 11px;
    }
    .btn {
      color: #a4abbd;
      .btn-group {
        margin-bottom: 6px;
      }
      .btn-group .separate {
        margin: 0 9px;
      }
      .btn-group .btn-item {
        transition: 0.3s;
        cursor: pointer;
      }
      .btn-group .btn-item:hover {
        color: #ff992b;
      }
      .btn-group .btn-item.selected {
        color: #ff992b;
      }
    }
  }
  .device-category-child {
    width: 500px;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.96);
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
    padding: 15px 23px 0;
    .device-category-item {
      margin-top: 28px;
    }
    .title {
      font-size: 14px;
      color: #fff;
      margin-bottom: 16px;
      height: 25px;
      border-radius: 4px;
      background-color: #ff992b;
      display: inline-flex;
      align-items: center;
      padding: 0 13px;
    }
    .btn {
      color: #000;
      .btn-group {
        margin-bottom: 6px;
      }
      .btn-group .separate {
        margin: 0 9px;
      }
      .btn-group .btn-item {
        transition: 0.3s;
        cursor: pointer;
      }
      .btn-group .btn-item:hover {
        color: #ff992b;
      }
    }
    .btn-close {
      height: 25px;
      display: inline-flex;
      align-items: center;
      color: #fff;
      background-color: #a0a0a0;
      padding: 0 11px;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 3;
      cursor: pointer;
      transition: 0.3s;
    }
    .btn-close:hover {
      background-color: #a5a5a5;
    }
    .btn-close:active {
      background-color: #9b9b9b;
    }
  }
  .device-category,
  .device-category-child {
    overflow: hidden;
    overflow-y: scroll;
    /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
    // 滚动条整体部分
    &::-webkit-scrollbar {
      width: 5px;
      height: 6px;
    }
    // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
    &::-webkit-scrollbar-button {
      display: none;
    }
    // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
    &::-webkit-scrollbar-thumb {
      background: rgba(144, 147, 153, 0.3);
      cursor: pointer;
      border-radius: 15px;
    }
    // 边角，即两个滚动条的交汇处
    &::-webkit-scrollbar-corner {
      display: none;
    }
    // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
    &::-webkit-resizer {
      display: none;
    }
  }
  /* 三级菜单样式 */
  .carousel {
    .title {
      cursor: pointer;
    }
  }
  .submenu {
    display: inline-flex;
    align-items: center;
    color: #a4abbd;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 6px;
    span::after {
      margin: 0 9px;
      padding: 8px 0;
      color: #a4abbd;
      cursor: auto;
      content: '/';
    }
  }
  .submenu:nth-child(1) {
    color: #ffa032;
  }
  .submenu:nth-child(3n) {
    span::after {
      margin: 0;
      content: '';
    }
  }
  .submenu:last-child {
    span::after {
      content: '';
    }
  }
  .submenu:hover {
    color: #ffa032;
  }
  .submenu-three {
    display: inline-flex;
    align-items: center;
    color: #727171;
    cursor: pointer;
    color: #000;
    margin-bottom: 8px;
    transition: 0.3s;
    span::after {
      margin: 0 9px;
      padding: 8px 0;
      color: #727171;
      cursor: auto;
      content: '/';
    }
  }
  .submenu-three:nth-child(4n) {
    span::after {
      margin: 0;
      content: '';
      margin-right: 9px;
    }
  }
  .submenu-three:last-child {
    span::after {
      content: '';
    }
  }
  .submenu-three:hover {
    color: #ffa032;
  }
</style>
