import { defineStore } from 'pinia'
import { ref } from 'vue'
// import { useNeedStore } from '@/store/needStore'
// import { useDeviceStore } from '@/store/deviceStore'

export const useRegionStore = defineStore('regionStore', () => {
  const regionData = ref({
    selectedCities: [],
    address: '全国',
  })

  const handleCityChange = (selectedCity) => {
    regionData.value.address = selectedCity
    console.log(regionData.value.address)
  }

  // watchEffect(() => {
  //   const { setNeedSearchParams } = useNeedStore()
  //   setNeedSearchParams(regionData.value)
  //   const { setDeviceSearchParams } = useDeviceStore()
  //   setDeviceSearchParams(regionData.value)
  // })

  return {
    regionData,
    handleCityChange,
  }
})
