<template>
  <div class="home">
    <Header :cityValue="regionStore.regionData.address" @changeCity="handleCityChange" />
    <div class="carousel">
      <Classification />
      <el-carousel :interval="5000">
        <!-- 轮播图 -->
        <el-carousel-item class="carousel-item" v-for="(item, index) in bannerList" :key="index"
          :style="{ 'background-image': 'url(' + item.img_url + ')' }">
        </el-carousel-item>
      </el-carousel>
    </div>
    <searchBar />
    <div class="container">
      <div class="w-1200">
        <section class="base-list">
          <div class="list-header">
            <div class="title"><i class="f-icon title-icon-rent"></i>最新出租</div>
            <span class="btn-more" @click="goDeviceList">更多设备</span>
          </div>
          <div class="card">
            <!-- 出租设备 -->
            <div class="card-item" v-for="(item, index) in productList.device" :key="index"
              @click="goDevice(item.device_lease_id)">
              <img :src="item.show_img" class="pic" alt="" @error.once="errorLoadImg" />
              <div class="body">
                <div class="price">
                  <small>¥</small>
                  <big>{{ item.device_rental_fee ? item.device_rental_fee.rental_fee : ''
                  }}</big>/{{ item.device_rental_fee ? item.device_rental_fee.rental_type : '' }}
                </div>
                <div class="content">{{ item.name }}</div>
                <div class="factory">
                  <div class="factory-name">
                    <i class="f-icon icon-factory"></i>{{ item.company_name }}
                  </div>
                  
                  <el-popover placement="top-start" width="200" trigger="hover"
                    :content="item.address">
                  <div class="location" slot="reference">{{ item.address }}
                  </div>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="base-list need-list">
          <div class="list-header">
            <div class="title"><i class="f-icon title-icon-need"></i>最新需求</div>
            <span class="btn-more" @click="goNeedList">更多需求</span>
          </div>
          <div class="card">
            <!-- 最新需求 -->
            <div class="card-item" v-for="(item, index) in productList.needs" :key="index"
              @click="goNeeds(item.device_seekrent_id)">
              <span class="tag">求租</span>
              <img :src="item.seekrent_img" class="pic" alt="" @error.once="errorLoadImg" />
              <div class="body">
                <div class="content">{{ item.name }}</div>
                <div class="factory">
                  <div class="factory-name">
                    <i class="f-icon icon-factory"></i>{{ item.company_name }}
                  </div>
                  <div class="location">{{ item.address }}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer />
    <right-nav />
  </div>
</template>

<script>
import searchBar from '@/components/searchBar.vue'
import { getBannerList, getProductList } from '@/api/home'
import Header from '@/components/header'
import Footer from '@/components/footer'
import RightNav from '@/components/rightNav'
import Classification from '@/components/classification.vue'
import { useRegionStore } from '@/store/regionStore'
import kits from '@/kits'
export default {
  setup() {
    const regionStore = useRegionStore()
    const { handleCityChange } = useRegionStore()

    return { regionStore, handleCityChange }
  },
  components: {
    Header,
    Footer,
    RightNav,
    searchBar,
    Classification,
  },
  data() {
    return {
      /* 轮播图列表 */
      bannerList: [],
      /* 首页列表 */
      productList: [],
      visible: false
    }
  },
  mounted() {
    /* 获取轮播图 */
    getBannerList()
      .then((res) => {
        if (res.status != 200) return
        this.bannerList = res.data
      })
      .catch((err) => {
        // 出错时要做的事情
        return err
      })
    /* 获取首页列表 */
    getProductList()
      .then((res) => {
        if (res.status != 200) return
        console.log(res);
        /* 循环判断图片 和 价格 */
        res.data.device.forEach((item) => {
          item.show_img = kits.img.url(item.show_img)
          item.device_rental_fee = JSON.parse(item.device_rental_fee_json)[0] != null ? JSON.parse(item.device_rental_fee_json)[0] : JSON.parse(item.device_rental_fee_json)
          if (item.device_rental_fee.rental_type == '0') {
            item.device_rental_fee.rental_type = '日'
          } else if (item.device_rental_fee.rental_type == '1') {
            item.device_rental_fee.rental_type = '月'
          } else if (item.device_rental_fee.rental_type == '2') {
            item.device_rental_fee.rental_type = '半年'
          } else {
            item.device_rental_fee.rental_type = '年'
          }
        })
        res.data.needs.forEach((item2) => {
          item2.seekrent_img = kits.img.url(JSON.parse(item2.seekrent_img)[0])
        })
        this.productList = res.data
        console.log(this.productList);
      })
      .catch((err) => {
        // 出错时要做的事情
        return err
      })
  },
  computed: {},
  methods: {
    toNavPage() {
      this.$router.push({
        name: 'MenuPage',
      })
    },
    /* 转跳 */
    goDevice(id) {
      this.$router.push({ name: 'deviceDetails', query: { id } })
    },
    goDeviceList() {
      this.$router.push({ name: 'deviceList' })
    },
    goNeeds(id) {
      this.$router.push({ name: 'needDetails', query: { id } })
    },
    goNeedList() {
      this.$router.push({ name: 'needList' })
    },
    errorLoadImg(e) {
      e.currentTarget.src = require("@/assets/images/temp/temp_01.png")
    }
  },
}
</script>

<style lang="scss" scoped>
.device-category,
.device-category-child {
  overflow: hidden;
  overflow-y: scroll;

  /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
  // 滚动条整体部分
  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
    display: none;
  }

  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 15px;
  }

  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
    display: none;
  }

  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
    display: none;
  }
}

.location {
  position: relative;
  width: 100px !important;
  height: 23px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 888;
}
</style>
