
import axios from "axios";
// import { useUserStore } from "@/modules/project_designer/store/user";

// import errorCode from "@/utils/errorCode";

//import { Message } from 'element-ui'
// import { Modal } from "ant-design-vue";

const getBackendUrl = () => {
   let url = "http://114.67.170.224:21880";
   //  let url = "http://localhost:21880";
    // console.log(process.env.NODE_ENV)
    // switch (process.env.NODE_ENV) {
    //     case "development": // 开发环境 localhost
    //         url = "http://114.67.170.224:21880";
    //         break;
    //     case "alpha":
    //         // console.log('alpah test env')
    //         break;
    //     case "production":
    //         url = "http://114.67.170.224:21880"; // 生产环境
    //         break;
    //     default:
    //         break;
    // }
    // console.log(process.env.NODE_ENV, "backend url:", url);
    return url;
};

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: getBackendUrl(),
    // 超时
    timeout: 10000,
});

function gettoken() {
    let token = localStorage.getItem("token")
    return token
}

// request拦截器
service.interceptors.request.use(
    config => {
        const token = gettoken()
        // 是否需要设置 token
        if (!config.headers) {
            config.headers = {}
        }
        const isToken = (config.headers || {}).isToken === false;
        if (token!=null && token != "" && !isToken) {
            config.headers["Authorization"] = "Bearer " + token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }

        //处理url
        if (!config.url.startsWith("/api/")) {
            config.url = "/api/request?url=" + config.url
        }


        // get请求映射params参数
        if (config.method === "get" && config.params) {
            let url = config.url + "?";
            for (const propName of Object.keys(config.params)) {
                const value = config.params[propName];
                var part = encodeURIComponent(propName) + "=";
                if (value !== null && typeof value !== "undefined") {
                    if (typeof value === "object") {
                        for (const key of Object.keys(value)) {
                            if (value[key] !== null && typeof value[key] !== "undefined") {
                                let params = propName + "[" + key + "]";
                                let subPart = encodeURIComponent(params) + "=";
                                url += subPart + encodeURIComponent(value[key]) + "&";
                            }
                        }
                    } else {
                        url += part + encodeURIComponent(value) + "&";
                    }
                }
            }
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }


        //处理 data
        if (config.data) {
            let _data = config.data;
            if (_data == null) {
                _data = {}
            }
            if (_data.inputs||_data.pagination) {
                config.data = _data;
            } else {
                config.data = { inputs: _data };
            }
        }
        return config;
    },
    error => {
        console.log(error);
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    res => {
        // 未设置状态码则默认成功状态
        const code = res.data.status || 200;
        // 获取错误信息
        const msg = res.data.msg;

        if (code === 401) {
            // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
            //     confirmButtonText: '重新登录',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }
            // ).then(() => {
            //     store.dispatch('LogOut').then(() => {
            //         location.href = '/index';
            //     })
            // }).catch(() => { });
            // Modal.error({
            //     closable: false,
            //     title: "系统提示",
            //     content: "登录状态已过期，请您重新登录",
            //     okText: "重新登录",
            //     onOk: (close: () => void) => {
            //         close();
            //         location.href = "../";
            //     },
            // });
            return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
        } else if (code === 500) {
            // Message({
            //     message: msg,
            //     type: 'error'
            // })
            //return Promise.reject(new Error(msg));
            return Promise.reject(msg);
        } else if (code !== 200) {
            // Notification.error({
            //     title: msg
            // })
            return Promise.reject(msg);
        } else {
            return res.data;
        }
    },
    error => {
        console.log("err" + error);
        let { message } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        // Message({
        //     message: message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error);
    }
);

export default service;
