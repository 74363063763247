
let kits = {

    str: {
        isNull(str) {
            return str == null || str.trim() == "";
        },
        notNull(str) {
            return !kits.str.isNull(str);
        },
        //从url获取参数
        urlsearch2Obj(str) {
            //去掉？
            //var str=str.slice(1);
            //根据“&”分割字符串
            var arr = str.split("&");
            //定义空的obj，保存对象
            var obj = {};

            if (arr.length < 2) {
                return obj
            }

            //删掉第一个
            arr = arr.slice(1);

            //循环遍历分割后的数组
            for (var p of arr) {
                //根据“=”分割
                var arr2 = p.split("=");
                //解构
                var [name, value] = arr2;
                //如果obj中的name为undefined就把值填进去，否则就连接
                //反序列
                if (obj[name] == undefined) {
                    obj[name] = decodeURIComponent(value);
                } else {
                    obj[name] = [].concat(value, obj[name])
                }
            }
            return obj;
        },

        //生成UUID（len UUID长度，默认标准36位，radix UUID基数，默认16
        getuuid(len, radix) {
            var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            var uuid = [], i;
            radix = radix || chars.length;

            if (len) {
                // Compact form
                for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
            } else {
                // rfc4122, version 4 form
                var r;

                // rfc4122 requires these characters
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4';

                // Fill in random data. At i==19 set the high bits of clock sequence as
                // per rfc4122, sec. 4.1.5
                for (i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | Math.random() * 16;
                        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                    }
                }
            }

            return uuid.join('');
        },

        //随机数生成36位UUID
        getuuid36() {
            var s4 = function () {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        },

    },
    arr: {
        getByIndex(arr, flag, item) {
            for (let index = 0; index < arr.length; index++) {
                if (arr[index][flag] == item[flag]) {
                    return index;
                }
            }
            return -1
        },

        delElByIndex(arr, index) {
            arr.splice(index, 1);
        },
        // kits.arr.tree_set(data , "parent_id" , "menu_id")
        //启动
        tree_set(arr, pkey, tabkey) {

            let arr_0 = []
            pkey = pkey == null ? "pid" : pkey
            tabkey = tabkey == null ? "id" : tabkey
            //找出 0级
            // for (let key in arr) {
            //     let item = arr[key];
            //     if (item[pkey] == 0) {
            //         arr_0.push(item)
            //     }
            // }

            // for (let key in arr_0) {
            //     let item = arr_0[key];
            //     kits.arr.tree_node(item, item[tabkey], arr, pkey, tabkey)
            // }

            for (let key in arr) {
                let item = arr[key];

                if (item[pkey] != 0) {
                    kits.arr.tree_node(item, item[tabkey], arr, pkey, tabkey)
                } else {
                    arr_0.push(item)
                }

            }

            return arr_0
        },

        //迭代
        tree_node(pnode, pid, arr, pkey, tabkey) {
            for (let key in arr) {

                let item = arr[key];

                if (item[tabkey] == pnode[pkey]) {
                    if (item.children == null) {
                        item.children = []
                    }
                    item.children.push(pnode)
                    return
                }
            }
        }

    },
    json: {

        isJSON(str) {
            if (typeof str == 'string') {
                try {
                    var obj = JSON.parse(str);
                    if (typeof obj == 'object' && obj) {
                        return true;
                    } else {
                        return false;
                    }

                } catch (e) {
                    console.log('error：' + str + '!!!' + e);
                    return false;
                }
            }
            //console.log('It is not a string!')
        }

    },
    /**
     * 使用 kits.img.url("xxxx/xxxx.png")
     */
    img: {
        url(urlstr,defurl) {
            if (urlstr == null || urlstr == "") {
                if(defurl != null && defurl != ""){
                    return   defurl
                }
                return ""
            }
            if (urlstr.startsWith("http")) {
                return urlstr
            } else {
                return "http://114.67.170.224:21880/api/view?url=" + urlstr
            }
        }
    },
    obj :{
        copy(obj){
            return JSON.parse(JSON.stringify(obj))
        }
    }
};

export default kits;
