<template>
    <div class="header">
        <div class="header-topbar">
            <div class="header-topbar-main">
                <div class="f-flex-center">
                    <div class="city" @mouseenter="showCityList">
                        <div class="city-name">
                            <i class="f-icon icon-location"></i>{{ cityValue }}
                        </div>
                        <div class="city-popup" v-if="citySignin" @mouseleave="citySignin = false">
                            <div class="city-popup-main">
                                <div class="province">
                                    <span class="province-item" @click="getCity" :data-value="item" v-for="(item, index) in bigCity" :key="index">{{ item }}</span>
                                </div>
                                <div class="letter">
                                    <span v-for="item in a" :key="item" @click="getRegion(item)">{{ item }}</span>
                                </div>
                                <div class="city-list">
                                    <div class="city-item" v-for="item in cityList" :key="item.area_id">
                                        <span class="label" v-if="item.children != null">{{ item.area_name }}:</span>
                                        <div class="content">
                                            <span @click="showCity(item2.area_name)" v-for="item2 in item.children" :key="item2.area_id">{{ item2.area_name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="btn-text">手机APP看机械</span>
                </div>
                <div class="f-flex-center">
                    <div class="user-info" v-if="is_Signin">
                        <div class="user-info-name">
                            {{
                                user.consumer_name
                            }}<span v-if="user.is_supplier == 1" class="tag">供应商</span><span class="tag" v-if="user.is_procet == 1">项目方</span>
                        </div>
                        <div class="user-info-popup">
                            <div class="f-flex-center">
                                <img v-if="user.consumer_head == null || user.consumer_head == '' " src="~@/assets/images/temp/temp_04.png"  class="avatar" @click="go({ name: 'myOrder' })" >
                                <img v-else class="avatar" :src="kits.img.url(user.consumer_head)"  @click="go({ name: 'myOrder' })" />
                                <div class="content">
                                    <div class="f-flex-between">
                                        <span class="tag" v-if="user.is_supplier == 1">供应商</span><span class="tag" v-if="user.is_procet == 1">项目方</span><span class="btn-quit" @click="quit">[退出]</span>
                                    </div>
                                    <div class="account">账号：{{ user.id }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-login" v-if="!is_Signin" @click="$router.push({ name: 'login' })">
                        <i class="f-icon icon-user"></i>请登录
                    </div>
                    <div class="btn-group">
                        <span class="btn-item" v-if="!is_Signin" @click="$router.push({ name: 'login', query: { title: '注册' } })">注册</span>
                        <i class="divider" v-if="user.is_procet == 1"></i>
                        <span class="btn-item" @click="go({ name: 'myOrder' })" v-if="user.is_procet == 1">我的订单</span>
                        <i class="divider" v-if="user.is_procet == 1"></i>
                        <span class="btn-item" @click="go({ name: 'rentalOrder' })" v-if="user.is_supplier == 1">出租订单</span>
                        <i class="divider" v-if="user.is_supplier == 1"></i>
                        <span class="btn-item" @click="go({ name: 'shopCart' })" v-if="user.is_procet == 1"><i class="f-icon icon-shop-cart"></i>购物车</span>
                        <i class="divider" v-if="user.is_supplier == 1"></i>
                        <span class="btn-item" @click="go({ name: 'myDevice' })" v-if="user.is_supplier == 1">我的设备</span>
                    </div>
                    <div class="msg btn-group">
                        <span class="btn-item" @click="
                            go({ name: 'personalMessage', params: { tabsCurrent: 1 } })
                        "><i class="f-icon icon-msg" slot-scope=""></i>消息<i v-if="msgnum > 0" class="num">{{ msgnum }}</i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-navbar">
            <div class="header-navbar-main">
                <div class="logo"></div>
                <div class="nav-bar">
                    <div class="nav-item" :class="{ selected: index == navBarCurrent }" v-for="(item, index) in navBarList" :key="index" @click="navBarChange(index, item.path)">
                        {{ item.title }}
                    </div>
                </div>
                <div class="publish-btn">
                    
                    <div class="publish-btn-item" v-if="user.is_supplier == 1" @click="go({ name: 'publishDevice' })">
                        <i class="f-icon icon-publish-white"></i>发布设备
                    </div>
                    <div class="publish-btn-item" v-if="user.is_procet == 1" @click="go({ name: 'publishNeed' })">
                        <i class="f-icon icon-publish"></i>发布求租
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getuser, setuser, getmsgnum } from "@/api/user";
import { getRegionList } from "@/api/home.js";

import kits from "@/kits";

export default {
    props: ["cityValue", "msgnuma"],
    emits: ["changeCity"],
    data() {
        return {
            navBarCurrent: 0,
            navBarList: [
                {
                    title: "首页",
                    path: "home",
                },
                {
                    title: "找设备",
                    path: "deviceList",
                },
                {
                    title: "找需求",
                    path: "needList",
                },
            ],
            msgnum: 0,
            is_Signin: false,
            kits: kits,
            user: {},
            a: ["A", "F", "G", "H", "J", "L", "N", "Q", "S", "T", "X", "Y", "Z"],
            bigCity: ["全部", "北京", "上海", "广州", "深圳", "南京", "重庆"],
            regionList: [],
            cityList: [],
            cityVal: "福州",
            citySignin: false,
        };
    },
    created() {
        let query = this.$route.query;
        if (query.cityVal) {
            this.cityVal = query.cityVal;
        } else {
            this.cityVal = "福州";
        }
        if (this.$route.name == "home") {
            this.navBarCurrent = 0;
        } else if (this.$route.name == "deviceList") {
            this.navBarCurrent = 1;
        } else if (this.$route.name == "needList") {
            this.navBarCurrent = 2;
        } else {
            this.navBarCurrent = null;
        }
    },
    mounted() {
        //获取消息
        this.get_msgnum();
        //获取用户信息
        let _user = getuser();
        // console.log("xxxxxxxxxxxx _user", _user);
        if (_user != null) {
            this.user = _user;
            this.is_Signin = true;
        }
        /* 获取地区列表并处理 */
        getRegionList()
            .then((res) => {
                if (res.status != 200) return;
                this.regionList = res.data;
                this.regionList.province.forEach((item) => {
                    this.regionList.city.forEach((item2) => {
                        if (item2.parent_id == item.area_id) {
                            if (item.children == null) {
                                item.children = [];
                            }
                            item2.area_name = item2.area_name.substring(0, 2);
                            item.children.push(item2);
                        }
                    });
                });
                this.cityList = this.regionList.province;
            })
            .catch((err) => {
                // 出错时要做的事情
                return err;
            });
    },
    methods: {
        navBarChange(index, item) {
            console.log(item);
            this.$router.push({ name: item });
        },
        go(data) {
            if (this.is_Signin) {
                this.$router.push(data);
            } else {
                this.$router.push({ name: "login" });
            }
        },
        quit() {
            setuser(null);
            this.$router.push({ name: "login" });
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
            }
            this.$router.push({ name: "login" });
        },
        /* 对应字母的地区列表 */
        getRegion(key) {
            let cityLists = [];
            this.regionList.province.map((item) => {
                if (item.first_char == key) cityLists.push(item);
            });
            this.cityList = cityLists;
        },
        showCity(val) {
            this.cityVal = val;
            this.$emit("changeCity", this.cityVal);
            //   this.$router.push({ name: 'needList', query: { cityVal: val } })
            console.log(val);
            this.citySignin = false;
        },
        showCityList() {
            this.citySignin = true;
        },
        getCity(e) {
            if (e.target.getAttribute("data-value") !== "全部") {
                this.cityVal = e.target.getAttribute("data-value");
                this.$emit("changeCity", this.cityVal);
                console.log(this.cityVal);

                this.citySignin = false;
            } else {
                this.cityList = this.regionList.province;
            }
        },

        get_msgnum() {
            getmsgnum().then((res) => {
                if (res.status == 200) {
                    this.msgnum = res.data;
                } else {
                    //this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.city-item {
    .label {
        min-width: 96px !important;
    }
}

.city-list {
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;

    /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
    // 滚动条整体部分
    &::-webkit-scrollbar {
        width: 5px;
        height: 6px;
    }

    // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
    &::-webkit-scrollbar-button {
        display: none;
    }

    // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
    &::-webkit-scrollbar-thumb {
        background: rgba(144, 147, 153, 0.3);
        cursor: pointer;
        border-radius: 15px;
    }

    // 边角，即两个滚动条的交汇处
    &::-webkit-scrollbar-corner {
        display: none;
    }

    // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
    &::-webkit-resizer {
        display: none;
    }
}
</style>
