<template>
    <div class="login">
        <div class="login-main">
            <div class="login-left">
                <div class="login-title">你好,欢迎使用<i class="login-logo"></i></div>
                <div class="app-link">
                    <img src="~@/assets/images/app_logo.png" class="icon-app" alt="" />
                    <div class="name">
                        使用APP查看<i class="f-icon login-icon-arrow"></i>
                    </div>
                </div>
            </div>
            <div class="login-box" v-if="step == 1">
                <div class="login-box-title">{{ title }}</div>
                <el-form ref="accountRuleForm" :model="accountForm" :rules="accountRules">
                    <div class="login-form account-form">
                        <div class="form-item">
                            <div class="form-input">
                                <i class="icon icon-phone"></i>
                                <el-form-item label="" prop="mobile">
                                    <el-input v-model="accountForm.mobile" placeholder="请输入手机号" clearable></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="form-item" v-if="ispassw == 'yz'">
                            <div class="form-input form-code-input">
                                <i class="icon icon-code"></i>
                                <el-form-item label="" prop="code">
                                    <el-input v-model="accountForm.code" placeholder="请输入验证码" clearable></el-input>
                                </el-form-item>
                                <el-button type="primary" :loading="code_loading" class="get-code" @click="get_phone_code">{{ code_text }}</el-button>
                            </div>
                        </div>
                        <div class="form-item" v-if="ispassw == 'password'">
                            <div class="form-input">
                                <i class="icon icon-code"></i>
                                <el-form-item label="" prop="password">
                                    <el-input :type="passwtype" v-model="accountForm.password" placeholder="请输入密码"> <i slot="suffix" class="el-icon-view" @click="changePass()"></i></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <el-button type="primary" class="login-btn" @click="onAccountSubmit">{{ this.title == "注册" ? "下一步" : "登陆" }}</el-button>
                    </div>
                </el-form>
                <div class="privacy-agreement">
                    <el-checkbox v-model="agreeCheckbox"></el-checkbox>我已同意<span class="btn-text" @click="$router.push({ name: 'serviceAgreement' })">《平台服务条款》</span>
                    <a v-if="ispassw == 'yz' & title == '登录'" class="showpass" @click="showpass('password')">密码登陆</a>
                    <a v-if="ispassw == 'password' & title == '登录'" class="showpass" @click="showpass('yz')">验证码登陆</a>
                </div>
            </div>

            <div class="login-box" v-if="step == 2">
                <div class="login-box-title">设置密码</div>
                <el-form ref="accountRuleForm" :model="accountForm" :rules="accountRules">
                    <div class="login-form account-form">
                        <div class="form-item">
                            <div class="form-input">
                                <i class="icon icon-code"></i>
                                <el-form-item label="" prop="password">
                                    <el-input :type="passwtype" v-model="password" placeholder="请输入密码"> <i slot="suffix" class="el-icon-view" @click="changePass()"></i></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-input">
                                <i class="icon icon-code"></i>
                                <el-form-item label="" prop="password">
                                    <el-input :type="passwtype" v-model="rpassword" placeholder="请重复密码"> <i slot="suffix" class="el-icon-view" @click="changePass()"></i></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <el-button type="primary" class="login-btn" @click="tor">注册</el-button>
                    </div>
                </el-form> 
            </div>
        </div>
    </div>
</template>

<script>
// import { login, setuser_info, phone_code } from "@/api/user";
import { setuser_info, send, check } from "@/api/user";

export default {
    name: "Login",
    data() {
        return {
            password: "",
            rpassword: "",
            step: 1,
            title: "登录",
            agreeCheckbox: false,
            code_loading: false,
            code_text: "获取验证码",
            ispassw: "yz",
            passwtype: "password",
            accountForm: {
                mobile: "",
                code: "",
                password: ""
            },
            accountRules: {
                mobile: [
                    { required: true, message: "请输入正确的手机号码", trigger: "blur" },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入正确的手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern:
                            /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[0-9])\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur",
                    },
                ],
                code: [
                    { required: true, message: "请输入验证码", trigger: "blur" },
                    { min: 6, max: 6, message: "长度在 6 个字符", trigger: "blur" },
                ],
                password: [{ min: 6, max: 20, message: "长度在 6-20 个字符", trigger: "blur" }]
            },
        };
    },
    mounted() {
        if (this.$route.query) {
            if (this.$route.query.title == "注册") {
                this.title = this.$route.query.title;
            }
        } else {
            //if(){
            //this.agreeCheckbox = true
            // }
        }
    },
    methods: {
        changePass() {
            if (this.passwtype == "password") {
                this.passwtype = "inp"
            } else {
                this.passwtype = "password"
            }
        },
        showpass(str) {
            this.ispassw = str
            if (this.ispassw == "password") {
                this.accountForm.password = ''
            }
        },
        tor() {
            if (this.password.length < 6 || this.password.length > 20) {
                this.$message({
                    message: '密码长度在 6-20 个字符！',
                    type: 'error'
                })
                return
            }
            if (this.password != this.rpassword) {
                this.$message({
                    message: '重复密码错误！',
                    type: 'error'
                })
                return
            }
            this.$refs.accountRuleForm.validate((valid) => {
                if (valid) {
                    this.$router.push({
                        name: "selectIdentity",
                        params: { mobile: this.accountForm.mobile, password: this.password },
                    });
                }
            })

        },
        onAccountSubmit() {
            this.$refs.accountRuleForm.validate((valid) => {
                if (valid) {
                    if (!this.agreeCheckbox) {
                        this.$message.error("请先同意《平台服务条款》!");
                        return;
                    }
                    check(this.accountForm).then((res) => {
                        //console.log(this.accountForm.mobile);
                        if (res.status == 200) {
                            if (this.title == "注册" || res.data == "新用户") {
                                // this.$router.push({
                                //     name: "selectIdentity",
                                //     params: { mobile: this.accountForm.mobile },
                                // });
                                this.step = 2
                            } else {
                                setuser_info(res.data);
                                this.$message.success("登陆成功");
                                setTimeout(() => {
                                    this.$router.push({ name: "home" });
                                }, 800);
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        get_phone_code() {
            console.log("get_phone_code");

            this.$refs.accountRuleForm.validateField("mobile", (emailError) => {
                console.log("get_phone_code valid", emailError);
                if (!emailError) {
                    this.code_loading = true;
                    send({ mobile: this.accountForm.mobile }).then((res) => {
                        if (res.status == 200) {
                            this.$message.success("短信发送成功!");
                            this.start_code_time();
                        } else {
                            this.$message.error(res.msg);
                            this.code_loading = false;
                        }
                    });
                } else {
                    this.$message.error(emailError);
                    return false;
                }
            });
        },
        start_code_time() {
            this.code_time = 60;
            this.do_code_time();
        },
        do_code_time() {
            if (this.code_time > 0) {
                this.code_text = "等待" + this.code_time + "秒";
                this.code_time--;
                setTimeout(() => {
                    this.do_code_time();
                }, 1000);
            } else {
                this.code_time = 0;
                this.code_text = "获取验证码";
                this.code_loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>
<style>
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.el-icon-view {
    font-size: 28px;
    padding-top: 10px;
}

.showpass {
    color: blue;
    float: right;
    cursor: pointer
}
</style>
