import Vue from 'vue'
import Router from 'vue-router'

//组件模块
import login from '@/pages/login/login'

import home from '@/pages/home/home'

Vue.use(Router)

const router = new Router({
    //  mode: 'history',
    routes: [
        { path: '/', redirect: "home" },
        { path: '/login', name: 'login', component: login },
        {
            path: '/home',
            name: 'home',
            component: home
        },
        {
            path: '/deviceList',
            name: 'deviceList',
            component: () =>
                import ('@/pages/device/devicePage'),
        },
        {
            path: '/needList',
            name: 'needList',
            component: () =>
                import ('@/pages/needList/needList')
        },
        {
            path: '/deviceDetails',
            name: 'deviceDetails',
            component: () =>
                import ('@/pages/deviceDetails/deviceDetails'),
        },
        {
            path: '/needDetails',
            name: 'needDetails',
            component: () =>
                import ('@/pages/needDetails/needDetails'),
        },
        {
            path: '/publishDevice',
            name: 'publishDevice',
            component: () =>
                import ('@/pages/publishDevice/publishDevice'),
        },
        {
            path: '/publishNeed',
            name: 'publishNeed',
            component: () =>
                import ('@/pages/publishNeed/publishNeed'),
        },
        {
            path: '/shopCart',
            name: 'shopCart',
            component: () =>
                import ('@/pages/shopCart/shopCart')
        },
        {
            path: '/deviceReserve',
            name: 'deviceReserve',
            component: () =>
                import ('@/pages/deviceReserve/deviceReserve'),
        },
        {
            path: '/serviceAgreement',
            name: 'serviceAgreement',
            component: () =>
                import ('@/pages/serviceAgreement/serviceAgreement'),
        },
        {
            path: '/selectIdentity',
            name: 'selectIdentity',
            component: () =>
                import ('@/pages/selectIdentity/selectIdentity'),
        },
        {
            path: '/projectApply',
            name: 'projectApply',
            component: () =>
                import ('@/pages/projectApply/projectApply'),
        },
        {
            path: '/supplierApply',
            name: 'supplierApply',
            component: () =>
                import ('@/pages/supplierApply/supplierApply'),
        },

        {
            path: '/reviseNeed',
            name: 'reviseNeed',
            component: () =>
                import ('@/pages/reviseNeed/reviseNeed'),
        },
        {
            path: '/reviseDevice',
            name: 'reviseDevice',
            component: () =>
                import ('@/pages/reviseDevice/reviseDevice'),
        },
        // {
        //     path: '/applyBill',
        //     name: 'applyBill',
        //     component: () => import('@/pages/personalCenter/applyBill'),
        // },
        // { path: "/personalCenter", name: "personalCenter", component: () => import('@/pages/personalCenter/personalCenter'),},
        {
            path: '/personalCenter',
            name: 'personalCenter',
            redirect: '/personalCenter/myDevice',
            component: () =>
                import ('@/pages/personalCenter/personalCenter'),
            children: [{
                    path: "applyBill",
                    name: "applyBill",
                    component: () =>
                        import ('@/pages/personalCenter/applyBill.vue'),
                },
                {
                    path: 'myDevice',
                    name: 'myDevice',
                    component: () =>
                        import ('@/pages/personalCenter/myDevice.vue'),
                },
                {
                    path: 'myOrder',
                    name: 'myOrder',
                    component: () =>
                        import ('@/pages/personalCenter/myOrder.vue'),
                },
                {
                    path: 'needManage',
                    name: 'needManage',
                    component: () =>
                        import ('@/pages/personalCenter/needManage.vue'),
                },
                {
                    path: 'orderManage',
                    name: 'orderManage',
                    component: () =>
                        import ('@/pages/personalCenter/orderManage.vue'),
                },
                {
                    path: 'rentalOrder',
                    name: 'rentalOrder',
                    component: () =>
                        import ('@/pages/personalCenter/rentalOrder.vue'),
                },
                {
                    path: 'settlementManage',
                    name: 'settlementManage',
                    component: () =>
                        import ('@/pages/personalCenter/settlementManage.vue'),
                },
                {
                    path: 'billManage',
                    name: 'billManage',
                    component: () =>
                        import ('@/pages/personalCenter/billManage.vue'),
                },
                {
                    path: 'contractManage',
                    name: 'contractManage',
                    component: () =>
                        import ('@/pages/personalCenter/contractManage.vue'),
                },
                {
                    path: 'contractDetails',
                    name: 'contractDetails',
                    component: () =>
                        import ('@/pages/personalCenter/contractDetails.vue'),
                },
                {
                    path: 'myCollection',
                    name: 'myCollection',
                    component: () =>
                        import ('@/pages/personalCenter/myCollection.vue'),
                },
                {
                    path: 'personalInfo',
                    name: 'personalInfo',
                    component: () =>
                        import ('@/pages/personalCenter/personalInfo.vue'),
                },
                {
                    path: 'orderDetails',
                    name: 'orderDetails',
                    component: () =>
                        import ('@/pages/personalCenter/orderDetails.vue'),
                },
                {
                    path: 'orderModifyDetails',
                    name: 'orderModifyDetails',
                    component: () =>
                        import ('@/pages/personalCenter/orderModifyDetails.vue'),
                },
                {
                    path: 'personalMessage',
                    name: 'personalMessage',
                    component: () =>
                        import ('@/pages/personalCenter/personalMessage.vue'),
                },
                {
                    path: 'orderCompleted',
                    name: 'orderCompleted',
                    component: () =>
                        import ('@/pages/personalCenter/orderCompleted.vue'),
                },
                {
                    path: 'orderModify',
                    name: 'orderModify',
                    component: () =>
                        import ('@/pages/personalCenter/orderModify.vue'),
                },
                {
                    path: 'deviceMap',
                    name: 'deviceMap',
                    component: () =>
                        import ('@/pages/personalCenter/deviceMap.vue'),
                },
                {
                    path: 'sysMessage',
                    name: 'sysMessage',
                    component: () =>
                        import ('@/pages/personalCenter/sysMessage.vue'),
                },
            ],
        },
    ]
})

router.afterEach(() => {
    window.scrollTo(0, 0)
})

export default router